.sec-pinewood-dinning {
  background-color: var(--color-white);
  width: 100%;
  padding: 80px 0;

  /* height: 500px; */
}

.con-pinewood-dinning {
  max-width: 1140px;
  margin: 0 auto;
}
.parts-pinewood-dinning {
  display: flex;
  gap: 40px;
}
.part-pinewood-dinning {
  /* position: relative; */
  /* width: 50%; */
}
/* .pinewood-dinning-img {
  width: 100%;
  height: 100%;
  min-height: 300px;
  min-width: 500px;
} */
.pinewood-dinning-heading {
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: var(--color-black) !important;
  text-align: center;
}
.pinewood-dinning-subheading {
  font-size: 24px;
  color: var(--color-golden);
  margin-bottom: 30px;
  text-align: center;
}
.pinewood-dinning-desc {
  font-size: 16px;
  color: var(--color-brand);
  text-align: justify;
  line-height: 1.5;
}

/* .section-center { */
/* margin: 0 auto; */
/* margin-top: 4rem; */
/* width: 100%; */
/* have to have a height */
/* height: 80vh; */
/* max-width: 800px; */
/* text-align: center; */
/* position: relative;
  display: flex;
  overflow: hidden;
} */
/* .person-img { */
/* border-radius: 50%; */
/* margin-bottom: 1rem; */
/* width: 100%;
  height: 100%;
  min-height: 200px;
  min-width: 300px;
  object-fit: cover; */
/* border: 4px solid var(--clr-grey-8); */
/* box-shadow: var(--dark-shadow); */
/* } */
/* .icon {
  font-size: 3rem;
  margin-top: 1rem;
  color: var(--clr-primary-5);
} */
/* .prev,
.next {
  position: absolute;
  top: 220px;
  transform: translateY(-50%);
  background: var(--clr-grey-5);
  color: var(--clr-white);
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
} */
/* .prev:hover,
.next:hover {
  background: var(--clr-primary-5);
} */
/* .prev {
  left: 0;
} */
/* .next {
  right: 0;
} */
