.App {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
}

.bann-img {
  height: 100%;
  width: 100%;
  padding: 0 15px;
  object-fit: cover;
}

img:last-of-type {
  padding-left: 0;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 500px;
}

.wrapper {
  position: absolute;
  display: flex;
}

.bann-sec {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}

.scr_image {
  min-height: 500px;
  min-width: 700px;
  max-height: 500px;
  max-width: 700px;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
