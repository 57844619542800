.sec-pinewood-about {
  background-color: #0b5345;
  width: 100%;
  padding: 80px 0;

  /* height: 500px; */
}

.con-pinewood-about {
  max-width: 1140px;
  margin: 0 auto;
}
.parts-pinewood-about {
  display: flex;
  gap: 40px;
}
.pinewood-about-img {
  width: 100%;
  height: 100%;
  min-height: 300px;
  min-width: 500px;
}
.pinewood-about-heading {
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: var(--color-white);
  text-align: center;
}
.pinewood-about-subheading {
  font-size: 24px;
  color: var(--color-golden);
  margin-bottom: 30px;
  text-align: center;
}
.pinewood-about-desc {
  font-size: 16px;
  color: var(--color-white);
  text-align: justify;
  line-height: 1.5;
}
