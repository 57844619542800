.sec-partner {
    width: 100%;
    padding-bottom: 60px;
    margin-top: 120px;
}

.parner-parts {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.partner-part2 {
    max-width: 1140px;
    margin: 0 auto;
}

.partner-img {
    width: 100%;
    height: 100%;
}

.partner-txt {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.partner-img-txt {
    position: absolute;
    top: 45%;
    left: 40%;
    color: #fff;
    width: 300px;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.603);
    padding-left: 5px;
    font-weight: bold;
}

.partner-img-txt-auth {
    font-weight: 400;
}


/* Media Query */

@media screen and (max-width: 1140px) {
    .partner-txt {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 1140px) {
    .partner-img-txt {
        top: 40%;
        left: 45%;
    }
}

@media screen and (max-width: 730px) {
    .partner-img-txt {
        top: 22%;
        left: 33%;
    }
}

@media screen and (max-width: 480px) {
    .partner-img-txt {
        top: 7%;
        left: 16%;
        font-size: 15px;
        width: 250px;

    }
}