.section-home-our-brands {
  width: 100%;
  padding: 80px 0;
  background-color: var(--color-white);
}

.container-home-our-brand {
  max-width: 1140px;
  margin: 0 auto;
}

.home-our-brands-heading {
  font-size: 32px;
  font-weight: 600;
  color: var(--color-brand);
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: center;
}
.home-our-brands-subheading {
  color: var(--color-golden);
  font-size: 22px;
  margin: 12px 0 1.2rem 0;

  text-align: center;
  font-weight: 600;
}

.blog_contain {
  /* width: 90%; */
  height: 350px;
  margin-top: 10px;
}

.slick-slide {
  height: 320px;
  border: 4px solid #f25634;
  box-sizing: border-box;
  margin-right: 10px;
  max-width: 300px;
  max-height: 300px;
}

.slick-slide img {
  height: 100%;
  width: 100%;
  /* border: 2.5px solid white; */
  box-sizing: border-box;
}
