.form_main_wrapp {
  min-height: 100vh;
  background: var(--color-brand);
  margin-top: 110px;
}

.form_base_wrapp {
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 4% 0;
}

.form_heading {
  color: var(--color-white);
  font-weight: 600;
}

.form_wrapp {
  border: 1px solid var(--color-white);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.form_input {
  color: var(--color-golden);
  background: transparent;
  border: 1px solid var(--color-golden);
  padding: 5px 10px;
  min-width: 400px;
  width: 100%;
  margin-bottom: 10px;
}

.form_input::placeholder {
  color: var(--color-golden);
}

.form_label {
  color: var(--color-white);
  font-size: large;
  margin-bottom: 2%;
}

.form_btn {
  color: var(--color-brand);
  background: var(--color-golden);
  border: 1px solid var(--color-brand);
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.form_btn:hover {
  color: var(--color-golden);
  background: var(--color-brand);
  border: 1px solid var(--color-golden);
  transition: ease-in-out 0.3s;
}

.arr_date {
  display: flex;
  align-items: center;
  gap: 2%;
  margin: 10px 0;
}

.arr_date > h6 {
  font-size: 18px;
  color: var(--color-white);
  margin: 0;
}

.arr_date > p {
  font-size: 16px;
  color: var(--color-golden);
  margin: 0;
}

@media (max-width: 720px) {
  .form_input {
    min-width: 300px;
  }

  .arr_date {
    flex-wrap: wrap;
  }
}
