.section-home-event {
  width: 100%;
  /* background-color: var(--color-whit); */
  background-color: var(--color-section-bg);
  padding: 80px 0;
}

.container-home-event {
  max-width: 1140px;
  margin: 0 auto;
}

.event-parts {
  display: flex;
  gap: 5%;
}

.event-part {
  width: 50%;
  position: relative;
}

.section-event-heading {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.event-part2 {
  display: flex;
  flex-direction: column;
  gap: 1%;
}

.home-event-img {
  width: 100%;
  height: 100%;
  transition: all 3s ease;
}

.home-event-img:hover {
  transform: scale(1.1);
}

.img-box {}

.section-home-event .event-part2-sub {
  max-height: 220px;
  min-width: 500px;
  position: relative;
  overflow: hidden;
}

.event-img-txt {
  position: absolute;
  left: 70px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  opacity: 1;
}

.event-img-txt-heading {
  font-size: 24px;

  color: var(--color-whte);
  text-transform: uppercase;
  color: var(--color-white);
}

.event-img-txt-heading:hover {
  color: var(--color-white);
}

.event-img-txt-sub {
  color: var(--color-white);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 10px;
}

.event-img-txt-link {
  display: inline-block;
  background: none;
  /* font-family: "Montserrat", sans-serif;  */
  white-space: nowrap;
  text-transform: uppercase;
  align-self: flex-start;
  cursor: pointer;
  line-height: normal;
  padding: 10px 20px;
  font-size: 16px;
  color: var(--color-white);
  min-width: 110px;
  border-width: 0;
  text-align: center;
  border: 1px solid #fff;
  transition: all 0.3s ease;
}

.event-img-txt-link:hover {
  background-color: var(--color-light-broun);
  border: none;
  cursor: pointer;
  color: #fff;
}

.section-home-event .event-part2-sub:before {
  content: "";
  position: absolute;
  left: -10%;
  top: -40%;
  width: 60%;
  height: 200%;
  background: rgba(0, 0, 0, 0.8);
  /* background: blue; */
  z-index: 2;
  -webkit-transform: rotate(-7deg);
  -ms-transform: rotate(-7deg);
  transform: rotate(-7deg);
  -webkit-transition: left 0.4s 0.3s;
  -o-transition: left 0.4s 0.3s;
  transition: left 0.4s 0.3s;
}

.section-center {
  /* margin: 0 auto; */
  /* margin-top: 4rem; */
  /* width: 100%; */
  /* have to have a height */
  /* height: 80vh; */
  /* max-width: 800px; */
  /* text-align: center; */
  position: relative;
  display: flex;
  overflow: hidden;
}

.person-img {
  /* border-radius: 50%; */
  /* margin-bottom: 1rem; */
  width: 100%;
  height: 100%;
  min-height: 10vh;
  min-width: 0;
  object-fit: cover;
  /* border: 4px solid var(--clr-grey-8); */
  /* box-shadow: var(--dark-shadow); */
}

.icon {
  font-size: 3rem;
  margin-top: 1rem;
  color: var(--clr-primary-5);
}

.prev,
.next {
  position: absolute;
  top: 220px;
  transform: translateY(-50%);
  background: var(--clr-grey-5);
  color: var(--clr-white);
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
}

.prev:hover,
.next:hover {
  background: var(--clr-primary-5);
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--transition);
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

article.lastSlide {
  transform: translateX(-100%);
}

article.nextSlide {
  transform: translateX(100%);
}

/* ------------------- media quary --------------------- */

@media (min-width: 800px) {
  .text {
    max-width: 45em;
  }

  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}

@media (max-width: 920px) {
  .event-parts {
    flex-direction: column;
  }

  .event-part,
  .event-part2 {
    min-width: 400px;
    min-height: 400px;
    width: 86%;
    gap: 10px;
  }

  .event-parts {
    flex-direction: column;
    align-items: center;
    gap: 11px;
    overflow: hidden;
  }
}

@media (max-width: 720px) {
  .event-parts {
    flex-direction: column;
  }

  .event-part {
    min-width: 400px;
    min-height: 400px;
  }

  .section-home-event .event-part2-sub {
    min-width: 400px;
  }

  .event-parts {
    flex-direction: column;
    align-items: center;
    gap: 11px;
  }
}