.hotel1-wrapp {
  display: flex;
  flex-direction: column;
  margin-top: 115px;

  /* text-align: center; */
}

.hotel1-sec1-wrapp {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  background: var(--color-brand);
  /* min-height: 100vh; */
}

.hotel1-sec1-base {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  gap: 5%;
  justify-content: space-between;
  padding-bottom: 5%;
}

.hotel-logo-part {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 4px;
}

.hotel-logo-text {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--color-golden);
}

/* .reviews_main{
  max-width: 90%;
  height: 500px;
  margin: 0 auto;
} */

.reviews_main {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;     /* Centers vertically if needed */
  height: 100vh;           /* Full viewport height (or adjust as needed) */
}

.reviews_main iframe {
  max-width: 80%;   /* Set the desired width */
  max-height: 80%;  /* Set the desired height */
  border: 0;
}

.hotel-logo {
  max-width: 160px;
  /* width: 100%; */
}

.sec1-des-main-wrapp {
  display: flex;
  flex-direction: column;
  gap: 10%;
  flex: 1;

  /* justify-content: space-between; */
}

.sec1-des-wrapp {
  display: flex;
  gap: 7%;
}

.hotel_single_page_number{
  font-size: 1.1rem;
  color: var(--color-black);
  font-weight: 400;
  flex: 8 1;
  text-align: start;
  line-height: 22px;
}

.hotel_single_page_number:hover{
  color: var(--color-black);
}

.sec-hotel-name-wrapp {
  display: flex;
  text-align: left;
  flex-direction: column;
  /* margin-top: 15%; */
  margin-top: 9%;
  color: var(--color-golden);
  min-width: 200px;
  justify-content: center;
}

.sec-hotel-name-wrapp > p {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 3px;
  margin-bottom: -4px;
}

.sec-hotel-name-wrapp > h1 {
  font-size: 40px;
  font-weight: 800;
  line-height: 45px;
  letter-spacing: -0.5px;
  margin-bottom: 14px;
  font-family: sans-serif;
  color: var(--color-golden);
  /* font-family: var(--font-base); */
}

.sec-hotel-name-wrapp > h3 {
  font-size: 35px;
  font-weight: 900;
  line-height: 45px;
  letter-spacing: 0px;
  color: var(--color-golden);
  /* margin-bottom: 14px; */
}

.shr {
  width: 20%;
  height: 1px;
  background: var(--color-white);
}

.sec1-des-img-wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex: 1;
}

.sec1-des-img {
  width: 80%;
  min-height: 640px;
  object-fit: cover;
}

.hotel1-text {
  font-size: 1.3rem;
  color: var(--color-white);
}

.hotel1-sec2-base {
  background: var(--color-white);
  min-height: 100vh;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.hotel1-sec2-list-wrapp {
  color: var(--color-brand);
  list-style: disc;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 2rem;
}

.hotel1-sec2-single-list {
  font-size: 18px;
  margin-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.sec2-foot-wrapp {
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  gap: 1rem;
  /* justify-content: space-between; */
}

.sec2-foot-single {
  max-width: 300px;
  display: flex;
  max-width: 100%;

  /* margin-bottom: 20px; */
  gap: 10px;
  text-align: start;
  align-items: center;
}

.map-container {
  flex: 1; /* This allows the container to grow and shrink as needed */
  overflow: hidden; /* Optional: hides any overflow content */
}

.map-link {
  word-wrap: break-word; /* Break long words */
  word-break: break-all; /* Break words if necessary */
  color: black;
  font-family: var(--font-base), sans-serif;
  font-weight: 400;
}

.sec2-foot-single:nth-child(1) {
  align-items: flex-start;
}

.sec2-foot-single > p {
  font-size: 1.1rem;
  color: var(--color-black);
  font-weight: 400;
  flex: 8;
  text-align: start;
  margin-bottom: 0px !important;
  line-height: 22px;
}

.sec2-foot-single > a {
  font-size: 1.1rem;
  color: var(--color-black);
  font-weight: 400;
  flex: 8;
  text-align: start;
  line-height: 22px;
}

.sec2-foot-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: var(--color-black);
  background: var(--color-golden);
  padding: 5px;
  /* flex: 1; */
}

.sec2-foot-no-wrapp {
  display: flex;
  flex-direction: column;
  width: auto;
  width: 100%;
  max-width: 300px;
  gap: 10px;
}


@media (min-width: 1000px) {
  .hotel1-sec1-base {
    padding-left: 1%;
    /* padding-bottom: 5%; */
    padding-bottom: 8%;
  }
}

@media (max-width: 920px) {
  .hotel1-sec1-base {
    flex-direction: column;
    gap: 2rem;
    padding: 0 2% 6% 2%;
  }

  .reviews_main iframe {
    max-width: 90%;
    max-height: 90%;
  }

  .sec1-des-img {
    width: 100%;
  }

  .sec1-des-main-wrapp {
    gap: 1rem;
  }

  .hotel1-sec2-base {
    min-height: 77vh;
    max-width: 880px;
    /* margin: 0 auto; */
    padding: 0 5%;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .sec2-foot-wrapp {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    gap: 1rem;
    justify-content: space-between;
    flex-direction: column;
    min-height: 225px;
  }
}

@media (max-width: 700px) {
  .sec-hotel-name-wrapp {
    min-width: none;
  }

  .sec-hotel-name-wrapp > h1 {
    margin-bottom: 14px;
    line-height: 33px;
    font-size: 35px;
    line-height: 1.2;
  }

  .sec-hotel-name-wrapp > p {
    font-size: 18px;
  }

  .sec-hotel-name-wrapp > h3 {
    font-size: 25px;
    line-height: 40px;
  }

  .sec1-des-img {
    min-height: 445px;
  }

  .hotel1-sec2-list-wrapp,
  .hotel1-text,
  .sec2-foot-single > p {
    font-size: 1rem;
  }

  .sec2-foot-icon {
    height: 30px;
    width: 30px;
    padding: 0;
    color: var(--color-golden);
    background: none;
    /* flex: 1 1; */
  }

  .sec2-foot-single > p {
    font-size: 1rem;
  }
}

.img-filter-main {
  /* border: 1px solid green; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  max-width: 1140px;
  margin: 30px auto;
  margin-top: 2rem;
  /* margin-bottom: 80px; */
  /* height: 4rem; */
  /* z-index: 10; */
  margin-top: 4rem;
}

.img-filter-room {
  /* border: 2px solid orange; */
}

.room-hover {
}

.img-filter-btn {
  width: 125px;
  /* background-color: var(--color-brand); */
  color: var(--color-brand);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  cursor: pointer;
  border: 1px solid #ddd;
}

.img-filter-btn-active {
  width: 125px;
  background-color: var(--color-brand);
  color: var(--color-golden);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  cursor: pointer;
}

/* Image Gallery */

.lightbox_main_wrapp {
  /* border: 1px solid green; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-x: scroll;
  width: 100%;
  gap: 12px;
  padding-left: 20px;
}

.image-card {
  width: 24%;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
}

#lightbox-img {
  height: 60vh;
  max-width: 90vw;
  object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lightbox_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
}

.lightbox_btn {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 920px) {
  .img-filter-main {
    margin-top: 50px;
  }
}

@media screen and (max-width: 960px) {
  .landing-profile-social-inner {
    max-width: 720px;
  }

  .landing-profile-social-btn {
    height: 50px;
  }

  .landing-profile-social-btn-first {
    height: 50px;
  }

  .landing-profile-other-btn {
    height: 60px;
  }

  .landing-profile-other-last-btn {
    height: 60px;
  }

  .last-btn-con {
    margin-right: 20px;
  }

  .landing-profile-social-icon-section {
    justify-content: center;
  }

  .hero-img {
    height: 40vh;
  }

  .about-company-con {
    padding-left: 15px;
  }

  .all-data-main-head {
    padding-left: 15px;
  }

  .lightbox_main_wrapp {
    overflow-x: scroll;
    flex-wrap: nowrap;
    width: 98vw;
  }

  .image-card {
    width: 200px;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
  }

  #lightbox-img {
    height: 60vh;
    max-width: 80vw;
    object-fit: cover;
  }
}

@media screen and (max-width: 730px) {
  .landing-profile-social-inner {
    max-width: 550px;
  }

  .landing-profile-social-btns {
    /* gap: 0px; */
    justify-content: space-between;
  }

  .landing-profile-con {
    gap: 130px;
  }

  .landing-profile-part2 {
    margin-left: -114px;
  }

  .hero-img {
    height: 35vh;
  }
}

@media screen and (max-width: 600px) {
  .landing-profile-social-inner {
    max-width: 475px;
  }

  .landing-profile-other-btn {
    height: 45px;
  }

  .landing-profile-other-last-btn {
    height: 45px;
  }

  .landing-profile-social-btn-icon {
    font-size: 15px;
  }

  .landing-profile-social-btn {
    height: 38px;
  }

  .hero-img {
    object-fit: fill;
    height: 31vh;
    width: 100%;
  }

  .landing-profile-social-btn-first {
    /* width: 350px; */
    height: 38px;
    border-radius: 12px;
    border: none;
    /* background-color: #c2052e; */
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }

  .landing-profile-social-btns {
    gap: 15px;
  }

  .landing-profile-con {
    gap: 25px;
  }

  .landing-profile-part2 {
    margin-left: 0px;
  }

  .landing-profile-con {
    margin-top: 45px;
  }

  .about-company-con {
    gap: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 500px) {
  .landing-profile-con {
    flex-direction: column;
    align-items: center;
  }

  .landing-profile-social-inner {
    max-width: 370px;
  }

  hero-img {
    object-fit: fill;
    height: 25vh;
  }

  .landing-profile-social-btns {
    flex-wrap: wrap;
    justify-content: center;
  }

  .landing-profile-part2 {
    gap: 15px;
    padding-left: 15px;
  }

  .toggle-btn-last {
    text-align: center;
    width: 100px;
    height: 40px;
    border-radius: 12px;
    position: fixed;
    bottom: 78px;
    right: 20px;
  }

  .toggle-btn-last-link {
    text-decoration: none;
    font-size: 12px;
    color: #fff;
  }

  .about-company-con {
    gap: 15px;
  }

  #lightbox-img {
    height: 21vh;
    max-width: 80vw;
    object-fit: cover;
  }

  /* 
    .last-btn-con {
        margin-right: 40px;
    } */
}
