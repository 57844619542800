.sec-pinewood-event {
  background-color: #e8f6f3;
  width: 100%;
  padding: 80px 0;

  /* height: 500px; */
}

.con-pinewood-event {
  max-width: 1140px;
  margin: 0 auto;
}
.parts-pinewood-event {
  display: flex;
  gap: 40px;
}
.pinewood-event-img {
  width: 100%;
  height: 100%;
  min-height: 300px;
  min-width: 500px;
}
.pinewood-event-heading {
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: var(--color-black) !important;
  text-align: center;
}
.pinewood-event-subheading {
  font-size: 24px;
  color: var(--color-golden);
  margin-bottom: 30px;
  text-align: center;
}
.pinewood-event-desc {
  font-size: 16px;
  color: var(--color-brand);
  text-align: justify;
  line-height: 1.5;
}
