/* .main-parent {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  background-color: var(--color-brand);
} */
/* .height {
  min-height: 100vh;
}
.gallery-heading {
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);
  margin-bottom: 30px;
}
.home-gallery-filter {
  display: flex;
  gap: 2%;
  align-items: center;
  justify-content: center;
}
.home-gallery-filter-cat {
  text-transform: uppercase;
  margin-bottom: 30px;
  color: var(--color-golden);
  font-weight: 500;
  /* display: block; */
/* padding: 7px 15px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
}
.home-gallery-filter-cat:hover {
  background-color: var(--color-light-broun);
  color: var(--color-white);
  cursor: pointer;
}

.container div {
  background-size: cover;
  background-position: center;
  /* border-radius: 15px; */
/* background-color: var(--color-brand); */
/* transition: 0.4s;
  min-height: 25vh;
  box-shadow: 0 0 0px rgba(0, 0, 0, 1); */
/* } */

/* .container {
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
}
.home-gallery-img {
  height: 100%;
  width: 100%;
}
.one {
  grid-area: 1 / 1 / span 2 / span 1;
}
.two {
  grid-area: 3 / 1 / span 1 / span 1;
}
.three {
  grid-area: 4 / 1 / span 1 / span 1;
}
.four {
  grid-area: 3 / 2 / span 2 / span 1;
}
.five {
  grid-area: 1 / 3 / span 1 / span 2;
}
.six {
  grid-area: 2 / 3 / span 2 / span 1;
}
.seven {
}
.eight {
}
.nine {
  grid-area: 4 / 3 / span 1 / span 2;
}
.ten {
}
.eleven {
}   */

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
.gallery-heading {
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);
  margin-bottom: 30px;
}

.home-gallery-filter {
  display: flex;
  gap: 2%;
  align-items: center;
  justify-content: center;
}

.home-gallery-filter-cat {
  text-transform: uppercase;
  margin-bottom: 30px;
  color: var(--color-golden);
  font-weight: 500;
  display: block;
  padding: 7px 15px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
}

.home-gallery-filter-cat:hover {
  background-color: var(--color-light-broun);
  color: var(--color-white);
  cursor: pointer;
}

.main-parent {
  width: 100%;
  /* height: 100%; */
  margin: 0;
  padding-top: 80px;
  background-color: var(--color-brand);
}

/* body {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: var(--color-brand);
  padding-bottom: 80px;
} */

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 210px);
  gap: 5px;
  width: 100%;
  padding: 5px;
}

.item {
  transition: all 0.5s;
}

.grid-container:hover .item {
  opacity: 0.2;
}

.item:hover {
  opacity: 1 !important;
}

.item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.r-span2 {
  grid-column: span 2;
}

.r-span3 {
  grid-column: span 2;
}
.c-span2 {
  grid-column: span 2;
  grid-row: span 2;
}
.c-span3 {
  grid-row: span 3;
  grid-column: span 2;
}

/* responsive gallery other */

.res-gallery-image {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  display: none;
}

.res-gallery-image img {
  height: 100%;
  width: 100%;
  transform: scale(1);
  transition: transform 0.4s ease;
}

.res-img-box {
  box-sizing: content-box;
  /* margin: 10px; */
  /* height: 250px; */
  /* width: 300px; */
  overflow: hidden;
  display: inline-block;
  color: white;
  position: relative;
  background-color: white;
}

.res-gallery-caption {
  position: absolute;
  bottom: 5px;
  left: 20px;
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.res-transparent-box {
  /* height: 250px; */
  /* width: 300px; */
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 0.3s ease;
}

.res-img-box:hover img {
  transform: scale(1.1);
}

.res-img-box:hover .transparent-box {
  background-color: rgba(0, 0, 0, 0.5);
}

.res-img-box:hover .caption {
  transform: translateY(-20px);
  opacity: 1;
}

.res-img-box:hover {
  cursor: pointer;
}

.res-gallery-caption > p:nth-child(2) {
  font-size: 0.8em;
}

.res-opacity-low {
  opacity: 0.5;
}

/* Media Query */

@media screen and (max-width: 420px) {
  .grid-container {
    display: none;
  }

  .res-gallery-image {
    display: block;
  }
}

/* .res-gal-main {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 1vmin;
  font-family: "Open Sans", sans-serif;
  color: white;
  background: #333;
}




.res-gal-main .res-gallery-container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80em;
  height: 80em;
}

.res-gal-main .res-gallery-container [class*=item] {
  width: 33%;
  height: 33%;
  border: 1px solid transparent;
  background: url() center center/cover no-repeat;
  transition: all 0.5s ease-in-out;
  z-index: 0;
}

.res-gal-main .res-gallery-container [class*=item]:hover {
  border: 1px solid white;
  z-index: 1;
}

.res-gal-main .res-gallery-container [class*=item]:nth-of-type(1) {
  top: 0;
  left: 0;
}

.res-gal-main .res-gallery-container [class*=item]:nth-of-type(2) {
  top: 0;
  left: 33%;
}

.res-gal-main .res-gallery-container [class*=item]:nth-of-type(3) {
  top: 0;
  left: 66%;
}

.res-gal-main .res-gallery-container [class*=item]:nth-of-type(4) {
  top: 33%;
  left: 0;
}

.res-gal-main .res-gallery-container [class*=item]:nth-of-type(5) {
  top: 33%;
  left: 33%;
}

.res-gal-main .res-gallery-container [class*=item]:nth-of-type(6) {
  top: 33%;
  left: 66%;
}

.res-gal-main .res-gallery-container [class*=item]:nth-of-type(7) {
  top: 66%;
  left: 0;
}

.res-gal-main .res-gallery-container [class*=item]:nth-of-type(8) {
  top: 66%;
  left: 33%;
}

.res-gal-main .res-gallery-container [class*=item]:nth-of-type(9) {
  top: 66%;
  left: 66%;
}

.res-gal-main .res-gallery-container input {
  display: none;
}

.res-gal-main .container input:checked+[class*=item] {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
} */
