/* section */
/* .section {
  width: 90vw;
  margin: 5rem auto;
  max-width: var(--max-width);
} */

@media screen and (min-width: 992px) {
  /* .section {
    width: 95vw;
  } */
}

/*
=============== 
Slider
===============
*/
.title {
  text-align: center;
  margin-bottom: 2rem;
}

.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.title span {
  font-size: 0.85em;
  color: var(--clr-primary-5);
  margin-right: 1rem;
  font-weight: 700;
}

.section-center {
  /* margin: 0 auto; */
  /* margin-top: 4rem; */
  margin-top: 110px;
  width: 100%;
  /* have to have a height */
  height: 85vh;
  /* max-width: 800px; */
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

.person-img {
  /* border-radius: 50%; */
  /* margin-bottom: 1rem; */
  width: 100%;
  height: 100%;
  min-height: 85vh;
  min-width: 100vw;
  object-fit: cover;
  /* border: 4px solid var(--clr-grey-8); */
  /* box-shadow: var(--dark-shadow); */
}

article h4 {
  text-transform: uppercase;
  color: var(--clr-primary-5);
  margin-bottom: 0.25rem;
}

.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: var(--clr-grey-3);
}

.text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
  color: var(--clr-grey-5);
}

.icon {
  font-size: 3rem;
  margin-top: 1rem;
  color: var(--clr-primary-5);
}

.prev,
.next {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background: var(--clr-grey-5);
  color: var(--clr-white);
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
}

.prev:hover,
.next:hover {
  background: var(--clr-primary-5);
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

@media (min-width: 800px) {
  .text {
    max-width: 45em;
  }

  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--transition);
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

article.lastSlide {
  transform: translateX(-100%);
}

article.nextSlide {
  transform: translateX(100%);
}

/* Availaility */
.gift-main-container {
  padding: 1.5% 0;
  position: absolute;
  bottom: 0;
  width: 100%;

  /* background-color: #11abad; */
  background-color: rgba(0, 0, 0, 0.603);

  /* background: transparent; */
}

.gift-container {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 45px;
  padding: 0 2%;
}

.gift-part {
  /* flex: auto; */
  color: #f8f9fa;
  font-weight: 600;
  /* margin-bottom: -16%; */
  font-size: 22px;
  text-transform: uppercase;
  margin: 0;
}

.gift-part-flex {
  display: flex;
  align-items: center;
  gap: 30px;
  flex: auto;
}

.gift-btn {
  /* flex: auto; */
  background: var(--color-light-broun);
  border: none;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 600;
  /* padding: 5% 10%; */
  border-radius: 0;
  /* padding: 0.3rem 2.2rem; */
  padding: 4px 30px;
  transition: 0.4s;
  cursor: pointer;
}

.gift-btn:hover {
  color: #fff !important;
}

.s-lbl {
  color: #f8f9fa;
  font-size: 16px;
  font-weight: 600;
  /* padding-left: 5%; */
  margin-bottom: 0.7rem;
  text-transform: uppercase;
}

.lbl-selector {
  display: flex;
  flex-direction: column;
  flex: auto;
  width: 100%;
}

.gift-selector {
  height: 2.2rem;
  border: 1px solid #fff;
  color: var(--color-white);
  background-color: rgba(0, 0, 0, 0.603);
  padding-left: 5%;
}

.gift-selector::placeholder {
  color: #fff;
  stroke: #fff;
  fill: #fff;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  stroke: #fff;
  fill: #fff;
}

input[type="date" i] {
  color: #fff;
} */

.gift-selector2 {
  color: #fff;
  fill: #fff;
  stroke: #fff;
}

.react-date-picker * {
  color: #fff;
  stroke: #fff;
}

.react-date-picker__wrapper {
  background: rgba(0, 0, 0, 0.603);
  border: 1px solid white;
  padding: 3px;
}

.react-calendar {
  background: rgba(0, 0, 0, 0.603) !important;
}

/* Media Query */

@media (max-width: 920px) {
  .lbl-selector {
    min-width: 100px;
    max-width: 200px;
  }

  .gift-container {
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
  }

  .gift-part-flex {
    flex-wrap: wrap;
    justify-content: center;
  }
}