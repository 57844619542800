.contact_sec {
  background: #fff;
  /* margin-top: 120px; */
  max-width: 1140px;
  margin: 120px auto 60px auto;
}

.contact {
  display: flex;
  flex-direction: row;
  padding: 5%;
  gap: 100px;
}

.contact-us-heading {
  color: var(--color-brand);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.875rem !important;
}

.c-form,
.c-details {
  flex: 1;
}

.c-form {
  display: flex;
  flex-direction: column;
}

.c-txt {
  border: 1px solid gray;
  height: 40px;
}

.c-lbl {
  margin-bottom: 5px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 1rem;
}

.c-msg-txt {
  border: 1px solid gray;
  height: 40px;
  height: 160px;
  padding: 0.5rem;
}

.quick-heading {
  color: var(--color-brand);
  text-transform: uppercase;
  font-weight: 600;
}

.con-info-flex {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.con-info-icon {
  height: 20px;
  width: 20px;
  stroke: #000;
  align-self: end;
}

.c-form-btn {
  margin-top: 20px;
  height: 40px;
  background: var(--color-brand);
  color: var(--color-golden);
  font-weight: 600;
  font-size: 120%;
  border: none;
  cursor: pointer;
  padding: 0.4rem 1rem;
  text-align: center;
  transition: all 0.5s ease;
}

.c-form-btn:hover {
  color: var(--color-brand);
  background: var(--color-golden);
  border: var(--color-golden);
}

.c-txt[type="text"],
.c-msg-txt[type="text"] {
  padding: 0 10px 0 10px;
  font-size: 120%;
  font-weight: 600;
}

.c-ulbl {
  margin-bottom: 0px;
  font-size: 100%;
  color: #000;
  font-weight: 900;
}

.c-dlbl {
  margin-top: 0px;
  font-size: 120%;
  font-weight: 400;
  color: #515151;
}

/* media query */

@media screen and (max-width: 920px) {
  .contact {
    flex-direction: column;
  }
}
