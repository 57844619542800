.fac-sec1 {
  background-color: var(--color-brand);
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 80px;
  margin-top: 80px;
}

.fac-sec2 {
  background-color: var(--color-white);
  width: 100%;
  /* height: 200vh; */
}

.fac-cont {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.cont-padding {
  padding-top: 20px;
  padding-bottom: 80px;
}

.fac-bg-img {
  width: 100%;
  height: 100%;
}

.fac-part {
  width: 40%;
  /* background: blue; */
  margin-top: 2rem;
}

.bann-img-part {
  width: 60%;
  height: 100%;
  transform: translateY(-25px);
}

.fac-heading {
  color: var(--color-golden);
  font-size: 16px;
  font-weight: 800;
}

.facility {
  color: var(--color-black);
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 500;
}

.bann-img-part1-img {
  width: 80%;
  height: 300px;
  margin-bottom: 5px;
}

.bann-part-2 {
  display: flex;
  gap: 5px;
  width: 80%;
}

.bann-img-part3-img {
  width: 49.5%;
  height: 49.5%;
}

.bann-img-part2-img {
  width: 49.5%;
  height: 49.5%;
}

.fac-list-margin-top {
  margin-top: 20px;
}

/*  */

.bnn-img-partt {
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 600px;
  overflow: hidden;
  align-items: flex-end;
  transform: translateY(-25px);
}

.bnn-img-first:nth-child(1) {
  width: 100%;
  height: 49.5%;
  margin-bottom: 0.7%;
}

.bnn-img-first:nth-child(2) {
  width: 49.5%;
  height: 49.5%;
}

.bnn-img-first:nth-child(3) {
  width: 49.5%;
  height: 49.5%;
}

/* Media Query */

@media (max-width: 920px) {
  .bann-img-part {
    width: calc(100% - 80px);
  }

  .bann-img-part1-img {
    width: 100%;
  }

  .bann-part-2 {
    gap: 1%;
    width: 100%;
  }

  .fac-sec1 {
    padding-bottom: 60px;
    margin-top: 60px;
  }

  .inner {
    height: 400px;
  }

  .scr_image {
    min-height: 400px;
    min-width: 500px;
    max-height: 400px;
    max-width: 500px;
  }
}

@media (max-width: 1150px) {
  .fac-cont {
    padding: 0 6%;
  }
}

@media (max-width: 720px) {
  .fac-cont {
    padding: 0 0px;
    flex-direction: column-reverse;
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 10%;
  }

  .bann-img-part {
    width: 100%;
  }

  .hotel1-sec2-base {
    padding: 0 10%;
  }

  .scr_image {
    min-height: 270px !important;
    min-width: 370px !important;
    max-height: 270px !important;
    max-width: 370px !important;
  }

  .inner {
    height: 290px !important;
  }

  .fac-part {
    width: 100%;
  }

  @media (max-width: 550px) {
    .scr_image {
      min-height: 270px !important;
      min-width: 325px !important;
      max-height: 270px !important;
      max-width: 325px !important;
    }
  }
}
