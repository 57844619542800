@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');


:root {
  --font-base: "Lato", sans-serif;
  --font-alt: "Roboto", sans-serif;
  --color-golden: #dcca87;
  --color-main: #471a55;
  --color-light-main: #501861;
  --color-light: #9456a7;
  --color-gray: #545454;
  --color-gray-light: #515151;
  --color-button: #e5e5e5;
  --color-grey: #aaaaaa;
  --color-white: #ffffff;
  --color-black: #000;
  --color-light-gray: #898989;
  --color-section-bg: #e8f6f3;
  --color-light-black: #232323;
  --color-light-broun: #a3814f;
  /* --color-brand: #0b5345; */
  /* --color-brand: #00344a; */
  --color-brand: #0c4e62;
  --max-width: 1170px;
  --fixed-width: 620px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-base);
  /* font-family: "Lato", sans-serif !important; */
  initial-scale: 1 !important;
}

body {
  font-family: var(--font-base);
}

figure {
  margin: 0px !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.line {
  margin-top: 10px;
  /* margin-bottom: 15px; */
  background-color: var(--color-golden);
  width: 100%;
  height: 1px;
}

::selection {
  color: #fff;
  background: #471a55;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* font-family: sans-serif; */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: unset;
  text-decoration: none;
}

a:hover {
  color: none !important;
}

.underline {
  text-decoration: underline !important;
}

.underline:hover {
  text-decoration: none !important;
}

strong,
b {
  font-weight: bold;
}

i {
  font-style: italic;
}

.text_normal {
  text-transform: none;
}

@media (min-width: 576px) and (max-width: 991px) {
  .container {
    max-width: 93%;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

html {
  scroll-behavior: smooth;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

body {
  height: 100%;
  width: 100%;
  font-family: var(--font-base);
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  position: relative;
  overflow-x: hidden;
  color: var(--color-gray-light);
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="checkbox"] {
  transform: scale(1.3);
}

::-moz-selection {
  text-shadow: none;
}

::selection {
  text-shadow: none;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

button[type="submit"] {
  cursor: pointer;
  border: none;
}

textarea {
  resize: none;
}

/* 
img {
  max-width: 100%;
  height: auto;
} */

a {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

/* a:hover {
  
} */

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  color: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-base), sans-serif;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.875rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1.1rem;
}

p {
  font-family: var(--font-base), sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.3em;
}

@media (min-width: 992px) {

  h1,
  .h1 {
    font-size: 3rem;
  }

  h2,
  .h2 {
    font-size: 2.25rem;
  }
}

/* .slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
} */

/* @media (-webkit-device-pixel-ratio: 1.5) {
  * {
    zoom: 0.9762;
  }
} */

.btn {
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-link {
  font-weight: 400;
  color: var(--color-main);
  background-color: transparent;
  font-size: 1.1rem;
}

.login-menu {
  align-items: center;
  justify-content: space-between;
}

.btn-primary {
  color: var(--color-white);
  background-color: var(--color-main);
  border-color: var(--color-main);
  font-weight: 400;
}

.btn-tertiary {
  color: var(--color-white);
  background-color: var(--color-main);
  border-color: var(--color-main);
  font-weight: 400;
  height: 32px !important;
  font-size: 0.8rem !important;
  width: 90px;
}

.btn-forth {
  color: var(--color-main);
  background-color: transparent;
  border-color: var(--color-main);
  font-weight: 400;
  padding: 3px 6px;
  /* height: 26px !important; */
  font-size: 0.6rem !important;
  width: -moz-fit-content;
  width: fit-content;
}

.btn-primary:hover,
.btn-secondary:hover,
.btn-forth:hover,
.btn-tertiary:hover {
  color: #fff;
  background-color: #721b8d;
  border-color: #721b8d;
}

.btn-outline-primary {
  color: var(--color-main);
  background-color: var(--color-white);
  border: 2px solid var(--color-main);
  font-weight: 400;
}

.btn-outline-primary:hover {
  color: var(--color-white);
  background-color: var(--color-main);
  border: 2px solid var(--color-main);
  font-weight: 400;
}

/* .btn-outline-primary:hover {
  color: var(--color-white);
  background-color: var(--color-main);
  border: 2px solid var(--color-main);
  font-weight: 400;
} */

.btn-secondary {
  color: var(--color-main);
  background-color: var(--color-white);
  border-color: var(--color-white);
  font-weight: 400;
  /* box-shadow: 0px 5.41696px 7.73852px rgba(0, 0, 0, 0.15); */
}

.btn-secondary:hover {
  background-color: var(--color-main);
  color: var(--color-white);
  border-color: rgb(255 255 255 / 67%);
}

.btn-outline-secondary {
  background-color: var(--color-main);
  color: var(--color-white);
  border: 2px solid var(--color-white);
  font-weight: 400;
}

.btn-outline-secondary:hover {
  background-color: var(--color-white);
  color: var(--color-main);
  border: 2px solid var(--color-main);
  font-weight: 400;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.gap-1 {
  gap: 0.5rem;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-evenly {
  justify-content: center;
  gap: 6%;
}

.head-1 {
  color: var(--color-main);
  font-weight: 900;
  margin-bottom: 1rem;
}

.head-2 {
  color: var(--color-light-main);
  font-weight: 700;
  margin-bottom: 1rem;
}

.bg-primary {
  background-color: var(--color-main);
}

.bg-secondary {
  background-color: #f6e5fb;
}

.text-primary {
  color: var(--color-light-main);
}

.text-light {
  color: var(--color-light);
}

.text-white {
  color: var(--color-white);
}

.mx-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.px-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-05 {
  margin-right: 0.5rem;
}

.ml-05 {
  margin-left: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.px-4 {
  padding: 2rem 0;
}

.text-center {
  text-align: center;
}

.font-weight-900 {
  font-weight: 900;
}

.font-weight-300 {
  font-weight: 300;
}

.MuiTabs-root {
  padding: 0 0.75rem;
  margin: 0 0 2rem !important;
}

.MuiButtonBase-root>span {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: capitalize;
}

.MuiButtonBase-root>label {
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
}

.Mui-selected>span {
  color: var(--color-main);
}

.Mui-selected>label {
  color: #5c1872;
  border: #5c1872 2px solid;
  border-radius: 5px;
  background: #fff;
  padding: 5px;
  width: 160px;
}

.font-14 {
  font-size: 0.975rem;
}

#root>main,
#root>div {
  /* padding-top: 73px; */
}

.login-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgb(0 0 0/ .4); */
  background: rgba(71, 26, 85, 0.8);
  cursor: pointer;
  z-index: 1024;
  width: 100%;
  height: 100%;
}

.login-screen {
  position: fixed;
  font-family: var(--font-base);

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1024;
  transform: translateY(-100%);
  transition: all 0.5s ease;
  opacity: 0;
  display: none;
}

.show.login-screen {
  opacity: 1;
  display: block;
  transform: translateY(0);
}

.signup_card_close-button {
  position: absolute;
  right: 14px;
  font-size: 24px;
  top: 14px;
  color: #c4c4c4;
  cursor: pointer;
}

.popupContent>img {
  width: 45%;
}

.popupContent>div {
  padding: 0 10px;
}

.signup__modal_main_section,
.signup__modal_main_section2,
.register__modal_main_section,
.ReactModal__Content {
  font-family: var(--font-base);
  position: relative;
  width: auto;
  margin: 0.5rem;
  background: #fff;
  border-radius: 20px;
  z-index: 1025;
  box-shadow: 4px 4px 20px rgb(0 0 0 / 25%);
  padding: 1.5rem;
}

.signup__input-up_text,
.profile-input>label {
  font-style: normal;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 5px;
}

.login_link {
  color: var(--color-main);
}

.input_box,
.form-control {
  width: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 32px;
  padding: 5px 10px;
  margin: 0 0 10px;
}

.passwod_box {
  position: relative;
}

.password_eye {
  cursor: pointer;
  position: absolute !important;

  right: 10px;
  top: 10px;
}

.forgate_modal_text.font-14,
.signup_footer,
.forgot-label.font-14 {
  font-size: 0.925rem;
}

.signup__text_section-heading {
  line-height: 1.1;
  font-family: var(--font-base);
  margin-bottom: 30%;
}

.ReactModal__Overlay {
  background: rgba(255 255 255, 0.8) !important;
  display: flex;
  align-items: center;
}

.text-black {
  color: #000;
}

@media (min-width: 600px) {

  .signup__modal_main_section,
  .register__modal_main_section {
    max-width: 585px;
    margin: 1.75rem auto;
  }

  .ReactModal__Content {
    width: 70%;
    overflow: scroll;
    margin: auto;
    height: 65%;
  }
}

@media (min-width: 992px) {
  .register__modal_main_section {
    max-width: 820px;
    margin: 1.75rem auto;
  }

  .ReactModal__Content.profile__modal_main_section {
    width: 750px;
    margin: 1.75rem auto;
  }
}

@media (max-width: 767px) {
  .ReactModal__Content {
    height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.w-100 {
  width: 100%;
}

.font-12 {
  font-size: 12px;
}

.px-lg-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-lg-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.borderBlack {
  border-color: rgba(0, 0, 0, 0.39);
}

.MuiPaper-root {
  box-shadow: none !important;
  border-radius: 10px !important;
}

.MuiTable-root thead th {
  font-size: 1rem !important;
  font-weight: 700 !important;
  min-width: initial !important;
  padding: 15px;
  border-bottom: 1px solid rgb(81 81 81 / 17%);
}

.MuiTable-root tbody td,
.MuiTable-root tbody th {
  font-size: 14px !important;
  font-weight: 400 !important;
  min-width: initial !important;
  padding: 10px;
  border-bottom: 1px solid rgb(81 81 81 / 17%);
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .MuiTable-root {
    min-width: 700 !important;
  }

  .MuiTabs-root {
    padding: 0 0rem;
  }

  .dis_large_tab_indicator.MuiTabs-root .MuiTabs-flexContainer button {
    padding: 12px !important;
    width: 96px;
    font-size: 1rem;
  }

  .dis_large_tab_indicator2.MuiTabs-root .MuiTabs-flexContainer button {
    padding: 12px !important;
    /* width: 96px; */
    font-size: 10px;
  }

  .MuiButtonBase-root>span {
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
  }

  /* .MuiTabs-root .MuiTabs-flexContainer button:first-child{
    padding-left: 0!important;
  } */
}

@media (max-width: 991px) {
  .mx-lg-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .d-md-none {
    display: none;
  }

  .d-md-block {
    display: block;
  }
}

@media (min-width: 992px) {
  .popupContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .popupContent>div {
    width: 50%;
    padding: 0 10px;
  }

  .MuiTable-root {
    min-width: initial !important;
  }

  .d-lg-none {
    display: none;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-flex {
    display: flex;
  }

  .mt-lg-4 {
    margin-top: 2.25rem;
  }

  .mx-lg-4 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .pt-lg-4 {
    padding-top: 2.25rem;
  }

  .text-lg-right {
    text-align: right;
  }

  .MuiTabs-root {
    padding: 0 1rem;
  }

  .text-lg-left {
    text-align: left;
  }

  .btn {
    font-size: 1.1rem;
  }

  .mb-lg-0 {
    margin-bottom: 0;
  }
}

.animate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  /* 	width: 350px; */
  /* 	font-size:26px; */
  font-family: Helvetica, sans-serif, Arial;
  animation: load 1.2s infinite 0s ease-in-out;
  animation-direction: alternate;
  text-shadow: 0 0 1px rgb(54, 0, 85);
}

.data_loader {
  height: 40vh;
  background-color: #fff;
  color: #471a55;
}

@keyframes load {
  0% {
    opacity: 0.08;
    /*         font-size: 10px; */
    /* 				font-weight: 400; */
    filter: blur(5px);
    letter-spacing: 3px;
  }

  100% {
    /*         opacity: 1; */
    /*         font-size: 12px; */
    /* 				font-weight:600; */
    /* 				filter: blur(0); */
  }
}

.loader {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 6px solid #ffffff;
  border-top-color: #471a55;
  box-sizing: border-box;
  background: transparent;
  animation: loading 1s linear infinite;
  margin: 0 auto;
  /* align-self: center; */
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  0% {
    transform: rotate(360deg);
  }
}