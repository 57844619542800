.section-footer-top {
  background: #2e2e2e;
  padding: 20px 0;
}

.container-footer-top {
  max-width: 1140px;
  margin: 0 auto;
  text-align: center;
}

.footer-top-eicon {
  width: 30px;
  height: 30px;
  color: var(--color-golden);
}

.footer-top-icon {
  width: 20px;
  height: 20px;
  color: var(--color-white);
}

.footer-top-part {
  display: flex;
  justify-content: space-around;
}

.footer-top-sub-part {
  display: flex;
  gap: 20px;
  align-items: center;
}

.footer-top-part-txt {
  display: inline-block;
  margin: 0;
  color: #fff;
  /* font-size: 25px; */
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: normal;
}

.footer-top-inp {
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
  padding: 5px 10px;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  min-width: 250px;
}

.footer-top-singup {
  vertical-align: middle;
  min-width: 135px;
  text-align: center;
  padding: 7px 10px;
  height: 40px;
  color: #fff;
  background-color: var(--color-golden);
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 10px;
  border: none;
}

.footer-top-inp::placeholder {
  color: var(--color-white);
}

.footer-center {
  padding-bottom: 40px;
  /* background-color: var(--color-black); */
  background-color: var(--color-brand);
}

.footer-center-part {
  padding-top: 40px;
}

.footer-logo-part {
  max-width: 100%;
  max-height: 100%;
  text-align: center;
}

.footer-logo {
  width: 100%;
  height: 100%;
  max-width: 135px;
  max-height: 100%;
}

.foo-logo-text {
  font-weight: 600;
  color: var(--color-golden);
}

.footer-links {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-top: 1px solid var(--color-golden);
  padding: 10px 0;
  border-bottom: 1px solid var(--color-golden);
}

.footer-link {
  color: var(--color-white);
  padding: 0;
  display: inline-block;
  transition: all 0.2s ease;
}

.footer-link:hover {
  color: var(--color-golden);
}

.footer-add-heading {
  color: var(--color-white);
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 300;
  text-align: center;
}

.footer-brand-name {
  color: var(--color-golden);
  font-weight: 800;
  font-size: 1.3;
  margin-top: 0;
  margin-bottom: -1px;
  text-align: center;
}

.footer-con-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-con-link {
  display: flex;
  gap: 10px;
  align-items: center;
}

.footer-con-icon {
  width: 1rem;
  height: 1rem;
  color: var(--color-golden);
}

.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer-addr {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--color-white);
}

.left-padding-footer-email {
  margin-left: -150px;
}

/* .footer-addr:hover {
  color: var(--color-golden);
} */
.footer-copyright {
  background-color: #101010;
  padding: 10px 0;
  text-align: center;
  color: #acacac;
  font-size: 12.22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-copyright>p {}

/* footer number wrapp */

.foot-cont-wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  gap: 10px;
}

.foot-num-wrapp {
  display: flex;
  gap: 10px;
  align-items: center;
}

.svr {
  width: 2px;
  height: 15px;
  background: var(--color-white);
}

.foot-num {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
}

.foot-num>a {
  color: var(--color-white);
}

.foot-num>a:hover {
  color: var(--color-white);
}

.foot-mail-wrapp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
}

.foot-mail-single {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  color: var(--color-white);
}


.footer-news-btn-subscribe {
  color: var(--color-white);
}

.footer-news-btn-subscribe:hover {
  color: var(--color-white);
}

.gift-btn:hover {
  color: #fff !important;
}

.footer-social-linkk {
  color: var(--color-white) !important;
}

.footer-social-linkk:hover {
  color: var(--color-white) !important;
}

/* -------------- media quary ------------------ */

@media (max-width: 920px) {
  .footer-top-part {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

@media (max-width: 720px) {
  .footer-top-sub-part {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}